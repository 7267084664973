// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-haeufige-fragen-js": () => import("./../../../src/pages/haeufige-fragen.js" /* webpackChunkName: "component---src-pages-haeufige-fragen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-unser-konzept-js": () => import("./../../../src/pages/unser-konzept.js" /* webpackChunkName: "component---src-pages-unser-konzept-js" */)
}

